import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { convertApolloError } from '../errors/validation.errors';
import { useLoginMutation, useMeLazyQuery, User } from '../generated/graphql';
import { useToggle } from '../hooks/toggle.hook';
import { clearLocalStorage, getAccessToken, setAccessToken } from '../local-storage/local-storage';
import { AUTH_ERROR, LOGIN, LOG_OUT } from '../store/auth/auth.store.actions';
import { authReducer, initialState } from '../store/auth/auth.store.reducer';
import { SnackbarContextType, useSnackbar } from './snackbar.context';

export type AuthContextType = {
  logout: () => void;
  login: (email: string, password: string, rememberMe: boolean) => Promise<boolean>;
  refreshAccessToken: () => Promise<void>;
  me: User | null;
  refreshMe: () => void;
};

const AuthContext = React.createContext<AuthContextType | null>(null);
AuthContext.displayName = 'AuthContext';
const useAuth = () => React.useContext(AuthContext);

const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { error } = useSnackbar() as SnackbarContextType;
  const [state, dispatch] = useImmerReducer(authReducer, initialState);
  const [loggedIn, { on: setLoggedOn, off: setLoggedOf }] = useToggle(false);
  const [loginUser] = useLoginMutation();
  const [getMe, { data: dataMe }] = useMeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
  });

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    if (loggedIn) {
      getMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    if (dataMe) {
      setLoggedOn();
      dispatch({ type: LOGIN, payload: dataMe.me });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMe]);

  const logout = () => {
    clearLocalStorage();
    setLoggedOf();
    dispatch({ type: LOG_OUT });
  };

  const login = async (
    email: string,
    password: string,
    rememberMe: boolean = false,
  ): Promise<boolean> =>
    loginUser({ variables: { email, password, rememberMe } })
      .then(async (res) => {
        const { accessToken } = res.data!.login;
        setAccessToken(accessToken);
        setLoggedOn();
        return true;
      })
      .catch((err: ApolloError) => {
        const serverErrors = convertApolloError(err);
        serverErrors.forEach((e) => error(e.message));
        dispatch({ type: AUTH_ERROR, payload: serverErrors });
        logout();
        return false;
      });

  const refreshAccessToken = async (): Promise<void> => {
    if (!getAccessToken()) {
      return;
    }
    fetch(`${process.env.REACT_APP_FW_API_BASE_URI}/refresh-token`, {
      method: 'POST',
      credentials: 'include',
    }).then(async (x) => {
      const { accessToken, ok } = await x.json();
      if (ok) {
        setAccessToken(accessToken);
        setLoggedOn();
      } else {
        logout();
      }
    });
  };

  const refreshMe = () => {
    try {
      getMe();
    } catch (err) {
      logout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        refreshAccessToken,
        me: state.me,
        refreshMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
