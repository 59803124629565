/* eslint-disable no-param-reassign */
import { Draft } from 'immer';
import {
  SET_WAITING,
  SET_WAITING_AND_WAITING_TEXT,
  SET_WAITING_OFF,
  SET_WAITING_ON,
  SET_WAITING_TEXT,
  SET_WAITING_TEXT_CREATING,
  SET_WAITING_TEXT_LOADING,
  SET_WAITING_TEXT_SAVING,
  SET_WAITING_TEXT_SUBMITTING,
  SET_WAITING_TEXT_UPLOADING,
} from './waiting.store.actions';
import { WaitingAction, WaitingState, WaitingText } from './waiting.store.types';

export const initialState: WaitingState = {
  waiting: false,
  text: WaitingText.LOADING,
};

export const waitingReducer = (draft: Draft<WaitingState>, action: WaitingAction) => {
  switch (action.type) {
    case SET_WAITING:
      draft.waiting = action.payload;
      break;
    case SET_WAITING_ON:
      draft.waiting = true;
      break;
    case SET_WAITING_OFF:
      draft.waiting = false;
      break;
    case SET_WAITING_TEXT:
      draft.text = action.payload;
      break;
    case SET_WAITING_TEXT_LOADING:
      draft.text = WaitingText.LOADING;
      break;
    case SET_WAITING_TEXT_SAVING:
      draft.text = WaitingText.SAVING;
      break;
    case SET_WAITING_TEXT_SUBMITTING:
      draft.text = WaitingText.SUBMITTING;
      break;
    case SET_WAITING_TEXT_CREATING:
      draft.text = WaitingText.CREATING;
      break;
    case SET_WAITING_TEXT_UPLOADING:
      draft.text = WaitingText.UPLOADING;
      break;
    case SET_WAITING_AND_WAITING_TEXT:
      draft.waiting = action.payload.waiting;
      draft.text = action.payload.text;
      break;
    default:
      break;
  }
};
