import 'fontsource-roboto';
import { useMemo, useState } from 'react';
import { ThemeType } from '../types/themes.types';

const themeLocalStorageKey = '__themed_app_provider_dark_mode__';

export const setThemeTypeLocalStorage = (type: ThemeType) =>
  localStorage.setItem(themeLocalStorageKey, type);

export const getThemeTypeLocalStorage = (): ThemeType | null =>
  (window.localStorage.getItem(themeLocalStorageKey) as ThemeType) ?? null;

export const useThemeSelector = () => {
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.LIGHT);

  const setTheme = (theme: ThemeType) => {
    setThemeTypeLocalStorage(theme);
    setThemeType(theme);
  };

  const handlers = useMemo(
    () => ({
      dark: () => {
        setTheme(ThemeType.DARK);
      },
      light: () => {
        setTheme(ThemeType.LIGHT);
      },
      set: (theme: ThemeType) => {
        setTheme(theme);
      },
    }),
    [],
  );

  return [themeType, handlers] as const;
};
