import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export interface LoadingDialogProps {
  open: boolean;
  title?: string;
  text?: string;
}

export const LoadingDialog = ({ open, title, text }: LoadingDialogProps) => (
  <Dialog disableBackdropClick disableEscapeKeyDown open={open} fullWidth>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers>
      <Box p={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress color="secondary" size={100} />

        {text && (
          <Box
            mt={5}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="subtitle1" align="center" color="textPrimary" component="p">
              {text}
            </Typography>
          </Box>
        )}
      </Box>
    </DialogContent>
  </Dialog>
);

LoadingDialog.defaultProps = { title: 'Loading..', text: undefined };
