import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { OperationDefinitionNode } from 'graphql';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from '../local-storage/local-storage';
import { AccessTokenClaims } from '../types/tokens.types';

const cache = new InMemoryCache({});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((op) => {
          const accessToken = getAccessToken();
          if (accessToken) {
            op.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: () => {
    const token = getAccessToken();

    if (!token) {
      return true;
    }

    try {
      const { exp } = jwtDecode<AccessTokenClaims>(token);
      if (Date.now() >= exp * 1000) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  },
  fetchAccessToken: () =>
    fetch(`${process.env.REACT_APP_FW_API_BASE_URI}/refresh-token`, {
      method: 'POST',
      credentials: 'include',
    }),
  handleFetch: (accessToken) => {
    setAccessToken(accessToken);
  },
  handleError: (err) => {
    console.warn('Your refresh token is invalid. Try to relogin');
    console.error(err);
  },
});

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_FW_WS_BASE_URI}/subscriptions`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => ({
      authToken: getAccessToken(),
    }),
  },
});

const httpLink = ApolloLink.from([
  refreshLink as any,
  requestLink,
  new HttpLink({
    uri: `${process.env.REACT_APP_FW_API_BASE_URI}/graphql`,
    credentials: 'include',
  }),
]);

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  link,
  cache,
});
