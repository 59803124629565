import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { ThemeType } from '../types/themes.types';

export const THEME_DRAWER_WIDTH_PIXELS = 275;
// The vertical scroll bar for the nav drawer needs to be taken into account for left padding
export const THEME_MAIN_CONTENT_ADDITIONAL_PADDING_LEFT_PIXELS = 40;

export const fairWhistleTheme = (type: ThemeType = ThemeType.LIGHT): Theme =>
  createMuiTheme({
    palette: {
      type,
      primary: {
        main: '#157dae',
        contrastText: '#fff',
        // light: '#8eacbb',
        // dark: '#34515e',
      },
      secondary: {
        main: '#ddd',
        contrastText: '#333',
      },
    },
    typography: {
      fontFamily: 'Avenir',
    },
    shape: {
      borderRadius: 2,
    },
    overrides: {
      MuiAppBar: {
        root: {
          borderBottom: '1px solid #ddd',
        },
      },
      MuiButton: {
        root: {
          fontSize: '1rem',
          boxShadow: 'none',
          textTransform: 'none',
          color: '#ffffff',
        },
        containedSecondary: {},
      },
      MuiSvgIcon: {
        root: {
          fontSize: '21px',
        },
        fontSizeSmall: {
          fontSize: '16px',
          margin: '2px',
        },
        fontSizeLarge: {
          fontSize: '24px',
          margin: '2px',
        },
      },
      MuiIcon: {
        fontSizeSmall: {
          fontSize: '12px',
          margin: '2px',
        },
      },
      MuiIconButton: {
        root: {
          fontSize: '1rem',
          boxShadow: 'none',
          textTransform: 'none',
          margin: '2px',
        },
        sizeSmall: {
          fontSize: '12px',
        },
        colorPrimary: {
          color: '#000',
          // border: '1px solid #666',
        },
        colorSecondary: {
          color: '#333 !important',
          border: '1px solid #999',
          borderRadius: '50em',
        },
        label: {
          color: '#000',
        },
      },
      MuiTypography: {
        root: {},
        caption: {
          color: '#333',
        },
      },
      MuiDrawer: {
        root: {
          '&& .MuiListItem-root': {
            borderBottom: '1px solid #eee',
            width: `${THEME_DRAWER_WIDTH_PIXELS}px`,
          },
          '&& .MuiListSubheader-root': {
            textTransform: 'uppercase',
            color: '#ccc',
            fontSize: '.75rem',
            letterSpacing: '.1rem',
            marginTop: '2rem',
            fontWeight: '600',
            paddingLeft: '0px',
            borderBottom: '1px solid #eee',
          },
        },
        paper: { padding: '2em' },
      },
      MuiAvatar: {
        root: {},
        colorDefault: {
          color: '#333',
          backgroundColor: '#fff!important',
        },
      },
      MuiListItem: {
        root: {
          color: '#666',
          transition: 'all 0.75s !important',
          '&:hover': {
            backgroundColor: '#fff !important',
            color: '#000',
            '& .MuiTypography-root': {
              fontWeight: '500',
            },
          },
          '&.Mui-selected': {
            backgroundColor: '#fff !important',
            color: '#000',
            '& .MuiTypography-root': {
              fontWeight: '900',
            },
            '&:hover': {
              backgroundColor: '#fff !important',
              color: '#000',
              '& .MuiTypography-root': {
                fontWeight: '900',
              },
            },
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '35px',
          color: '#999',
        },
      },
      MuiTable: {
        root: {
          background: 'transparent',
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButton: {
        disableRipple: true,
        variant: 'contained',
        color: 'primary',
        disableElevation: true,
      },
      MuiTextField: {
        variant: 'standard',
      },
      MuiCard: {
        elevation: 1,
      },
      MuiPaper: {
        elevation: 0,
      },
    },
  });
