import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { FWServerError, FWServerErrorCode } from '../types/errors.types';

export const convertApolloError = (err: ApolloError): FWServerError[] => {
  const serverErrors: FWServerError[] = [];

  err.graphQLErrors.forEach((apolloError) => {
    switch (apolloError.extensions!.code) {
      case FWServerErrorCode.ARGUMENT_VALIDATION_ERROR_CODE:
        serverErrors.push(...convertArgumentValidationErrors(apolloError));
        break;
      default:
        serverErrors.push({
          code: apolloError.extensions!.code,
          message: apolloError.message,
        });
        break;
    }
  });

  return serverErrors;
};

const convertArgumentValidationErrors = (err: GraphQLError): FWServerError[] => {
  const serverErrors: FWServerError[] = [];
  const validationErrors = err.extensions!.exception.validationErrors as any[];
  validationErrors.forEach((validationError) => {
    const { constraints } = validationError;
    Object.values(constraints).forEach((v) =>
      serverErrors.push({
        code: 'Validation Error',
        message: v as string,
      }),
    );
  });
  return serverErrors;
};
