export interface FWServerError {
  code: string;
  message: string;
}

export enum FWServerErrorCode {
  ARGUMENT_VALIDATION_ERROR_CODE = 'ARGUMENT_VALIDATION_ERROR',
}

export const ARGUMENT_VALIDATION_ERROR_MESSAGE = 'Argument Validation Error';
