import React, { useEffect } from 'react';
import { FullPageSpinner } from './components/progress/full-page-spinner/full-page-spinner.component';
import { AuthContextType, useAuth } from './context/auth.context';
import { useWaiting } from './hooks/waiting.hook';
import { configureFontAwesomeIcons } from './icons/font-awesome.icons';

configureFontAwesomeIcons();

const AuthenticatedApp = React.lazy(() => import('./authenticated-app/authenticated-app.app'));
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app/unauthenticated.app'));

const App = () => {
  const { me, refreshAccessToken } = useAuth() as AuthContextType;
  const [{ waiting }, { waitingOff }] = useWaiting();

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    const load = async () => {
      await refreshAccessToken();
      waitingOff();
    };

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (waiting) {
    return <FullPageSpinner />;
  }

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {me ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
