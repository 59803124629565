import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import 'fontsource-roboto';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from '../App';
import { getThemeTypeLocalStorage, useThemeSelector } from '../hooks/theme-selector.hook';
import { fairWhistleTheme } from '../themes/themes';
import { ThemeType } from '../types/themes.types';
import AppProvider from './app-context';

export type ThemedAppContextType = {
  themeType: ThemeType;
  setThemeType: (type: ThemeType) => void;
};

const ThemedAppContext = React.createContext<ThemedAppContextType | null>(null);
ThemedAppContext.displayName = 'ThemedAppContext';
const useThemedApp = () => React.useContext(ThemedAppContext);

const ThemedAppProvider: React.FC<React.ReactNode> = ({ children }) => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [theme, { set }] = useThemeSelector();

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(
    () => {
      const load = async () => {
        const savedTheme = getThemeTypeLocalStorage();
        if (savedTheme) {
          set(savedTheme);
        } /* else if (prefersDarkMode) {
        set(ThemeType.DARK);
      } */
      };

      load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      /* prefersDarkMode */
    ],
  );

  return (
    <ThemedAppContext.Provider
      value={{
        themeType: theme,
        setThemeType: set,
      }}
    >
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={fairWhistleTheme(theme)}>
          <CssBaseline />
          <BrowserRouter>
            <AppProvider>
              <App />
            </AppProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      </StylesProvider>
    </ThemedAppContext.Provider>
  );
};

export { ThemedAppProvider, useThemedApp };
