import React, { useMemo } from 'react';
import { useImmerReducer } from 'use-immer';
import { LoadingDialog } from '../components/dialogs/loading-dialog/loading-dialog.component';
import {
  SET_WAITING,
  SET_WAITING_AND_WAITING_TEXT,
  SET_WAITING_OFF,
  SET_WAITING_ON,
  SET_WAITING_TEXT,
  SET_WAITING_TEXT_CREATING,
  SET_WAITING_TEXT_LOADING,
  SET_WAITING_TEXT_SAVING,
  SET_WAITING_TEXT_SUBMITTING,
  SET_WAITING_TEXT_UPLOADING,
} from '../store/waiting/waiting.store.actions';
import { waitingReducer } from '../store/waiting/waiting.store.reducer';
import { WaitingText } from '../store/waiting/waiting.store.types';

export const useWaiting = (
  initialText: string | WaitingText = WaitingText.LOADING,
  initialWaiting: boolean = false,
  additionalWaits: boolean = false,
  subtitle: string | undefined = undefined,
) => {
  const [state, dispatch] = useImmerReducer(waitingReducer, {
    waiting: initialWaiting,
    text: initialText,
  });

  const handlers = useMemo(
    () => ({
      waitingOn: () => {
        dispatch({ type: SET_WAITING_ON });
      },
      waitingOff: () => {
        dispatch({ type: SET_WAITING_OFF });
      },
      updateWaiting: (waiting: boolean) => {
        dispatch({ type: SET_WAITING, payload: waiting });
      },
      setToLoadingText: () => {
        dispatch({ type: SET_WAITING_TEXT_LOADING });
      },
      setToSavingText: () => {
        dispatch({ type: SET_WAITING_TEXT_SAVING });
      },
      setToSubmittingText: () => {
        dispatch({ type: SET_WAITING_TEXT_SUBMITTING });
      },
      setToCreatingText: () => {
        dispatch({ type: SET_WAITING_TEXT_CREATING });
      },
      setToUploadingText: () => {
        dispatch({ type: SET_WAITING_TEXT_UPLOADING });
      },
      updateWaitingText: (text: string) => {
        dispatch({ type: SET_WAITING_TEXT, payload: text });
      },
      setWaitingAndWaitingText: (waiting: boolean, text: string) => {
        dispatch({ type: SET_WAITING_AND_WAITING_TEXT, payload: { waiting, text } });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dialog = (
    <LoadingDialog open={additionalWaits || state.waiting} title={state.text} text={subtitle} />
  );

  return [state, handlers, dialog] as const;
};
