/* eslint-disable no-param-reassign */
import { Draft } from 'immer';
import { AUTH_ERROR, LOGIN, LOG_OUT } from './auth.store.actions';
import { AuthAction, AuthState } from './auth.store.types';

export const initialState: AuthState = {
  me: null,
  errors: null,
};

export const authReducer = (draft: Draft<AuthState>, action: AuthAction) => {
  switch (action.type) {
    case LOGIN:
      draft.me = action.payload;
      draft.errors = null;
      break;
    case LOG_OUT:
      draft.me = null;
      break;
    case AUTH_ERROR:
      draft.me = null;
      draft.errors = action.error;
      break;
    default:
      break;
  }
};
