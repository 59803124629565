import 'fontsource-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemedAppProvider } from './context/themed-app.context';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Uncomment to enable HotJar and Google TagManager
// initGtm();

const Index = () => <ThemedAppProvider />;

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
