import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { apolloClient } from '../apollo/apollo';
import { AuthProvider } from './auth.context';
import { OrgLeagueSeasonsProvider } from './org-league-season.context';
import { SnackbarProvider } from './snackbar.context';
import { UserRoleProvider } from './user-role.context';

const AppProvider: React.FC<React.ReactNode> = ({ children }) => (
  <SnackbarProvider>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <UserRoleProvider>
          <OrgLeagueSeasonsProvider>{children}</OrgLeagueSeasonsProvider>
        </UserRoleProvider>
      </AuthProvider>
    </ApolloProvider>
  </SnackbarProvider>
);

export default AppProvider;
