/* eslint-disable no-param-reassign */
import { Draft } from 'immer';
import {
  CLEAR_CURRENT_CONTEXT,
  SET_ALL_CONTEXTS,
  SET_ALL_CONTEXTS_AND_RESTORE_CURRENT_CONTEXT,
  SET_CURRENT_CONTEXT,
} from './user-context.store.actions';
import { UserContextAction, UserContextState } from './user-context.store.types';

export const initialState: UserContextState = {
  allContexts: [],
  currentContext: null,
};

export const userContextReducer = (draft: Draft<UserContextState>, action: UserContextAction) => {
  switch (action.type) {
    case SET_ALL_CONTEXTS:
      draft.allContexts = action.payload;
      draft.currentContext = action.payload.length > 0 ? action.payload[0] : null;
      break;
    case SET_ALL_CONTEXTS_AND_RESTORE_CURRENT_CONTEXT:
      draft.allContexts = action.payload.allContexts ?? [];
      draft.currentContext = action.payload.currentContext ?? null;
      break;
    case SET_CURRENT_CONTEXT:
      draft.currentContext = action.payload;
      break;
    case CLEAR_CURRENT_CONTEXT:
      draft.currentContext = null;
      break;
    default:
      break;
  }
};
