import { library } from '@fortawesome/fontawesome-svg-core';
import { faMessagePlus, faMessages } from '@fortawesome/pro-regular-svg-icons';
import {
  faBullhorn,
  faCheckCircle,
  faComments,
  faFileChartColumn,
  faFileDownload,
  faFileImport,
  faFileSignature,
  faFileUpload,
  faFlag,
  faHourglassHalf,
  faMagic,
  faShirt,
  faTasks,
  faTshirt,
} from '@fortawesome/pro-solid-svg-icons';

export const configureFontAwesomeIcons = () => {
  library.add(faBullhorn);
  library.add(faFileDownload);
  library.add(faFileUpload);
  library.add(faMagic);
  library.add(faFileImport);
  library.add(faHourglassHalf);
  library.add(faComments);
  library.add(faCheckCircle);
  library.add(faFileSignature);
  library.add(faTasks);
  library.add(faTshirt);
  library.add(faMessagePlus);
  library.add(faMessages);
  library.add(faFileChartColumn);
  library.add(faShirt);
  library.add(faFlag);
};
