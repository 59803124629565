export interface SnackbarState {
  show: boolean;
  message: string;
  severity: SnackBarSeverity;
}

export interface SnackbarAction {
  type: string;
  payload?: any;
}

export enum SnackBarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
