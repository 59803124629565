import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import {
  League,
  OrganizationFeature,
  Season,
  useGetMyOrganizationContextLazyQuery,
} from '../generated/graphql';
import { SET_CURRENT_ORG_LEAGUE_SEASON } from '../store/org-league-seasons/org-league-seasons.store.actions';
import {
  initialState,
  orgLeagueSeasonsReducer,
} from '../store/org-league-seasons/org-league-seasons.store.reducer';
import { UserRoleTypes } from '../store/user-context/user-context.store.types';
import { AuthContextType, useAuth } from './auth.context';
import { UserRoleContextType, useUserRole } from './user-role.context';

export type OrgLeagueSeasonsContextType = {
  currentLeague: League | null;
  currentSeason: Season | null;
  orgFeatures: OrganizationFeature[];

  setAll: (
    newFeatures: OrganizationFeature[],
    newLeague: League | null,
    newSeason: Season | null,
  ) => void;
};

const OrgLeagueSeasonsContext = React.createContext<OrgLeagueSeasonsContextType | null>(null);
OrgLeagueSeasonsContext.displayName = 'OrgLeagueSeasonsContext';
const useOrgLeagueSeasons = () => React.useContext(OrgLeagueSeasonsContext);

interface OrgLeagueSeasonsProviderProps {
  children?: React.ReactNode;
}

const OrgLeagueSeasonsProvider = ({ children }: OrgLeagueSeasonsProviderProps) => {
  const [getMyOrgContext, { data: dataMyOrgContext }] = useGetMyOrganizationContextLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
  });
  const history = useHistory();
  const { me } = useAuth() as AuthContextType;
  const { currentContext } = useUserRole() as UserRoleContextType;
  const [state, dispatch] = useImmerReducer(orgLeagueSeasonsReducer, initialState());

  // #region Load Data

  const loadOrgContext = async () => {
    if (currentContext?.membership?.orgId) {
      await getMyOrgContext({
        variables: {
          orgId: currentContext!.membership!.orgId,
          leagueId: currentContext!.leagueMembership?.id
            ? currentContext!.leagueMembership?.id
            : undefined,
          seasonId: currentContext!.leagueMembership?.currentSeasonId
            ? currentContext!.leagueMembership?.currentSeasonId
            : undefined,
        },
      });
    }
  };

  // #endregion

  // #region Use Effects

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    const loadForContext = async () => {
      await updateFromContext();
    };
    loadForContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContext]);

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    if (currentContext) {
      if (dataMyOrgContext?.getMyOrganizationContext) {
        setAll(
          dataMyOrgContext.getMyOrganizationContext.orgFeatures as OrganizationFeature[],
          (dataMyOrgContext.getMyOrganizationContext.league as League) ?? null,
          (dataMyOrgContext.getMyOrganizationContext.season as Season) ?? null,
        );
      } else {
        setAll([], null, null);
      }
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMyOrgContext]);

  // #endregion

  const updateFromContext = async () => {
    if (!currentContext) {
      setAll([], null, null);
      return;
    }

    switch (currentContext.type) {
      case UserRoleTypes.LEAGUE_MEMBERSHIP:
      case UserRoleTypes.ORGANIZATION_ADMIN:
      case UserRoleTypes.ORGANIZATION_USER:
      case UserRoleTypes.ORGANIZATION_ASSOCIATE: {
        await loadOrgContext();
        break;
      }
      default:
        setAll([], null, null);
        history.push('/');
        break;
    }
  };

  // #region Actions

  const setAll = (
    newFeatures: OrganizationFeature[],
    newLeague: League | null,
    newSeason: Season | null,
  ) => {
    if (me) {
      dispatch({
        type: SET_CURRENT_ORG_LEAGUE_SEASON,
        payload: { newFeatures, newLeague, newSeason },
      });
    }
  };

  // #endregion

  return (
    <OrgLeagueSeasonsContext.Provider
      value={{
        currentLeague: state.currentLeague,
        currentSeason: state.currentSeason,
        orgFeatures: state.orgFeatures,

        setAll,
      }}
    >
      {children}
    </OrgLeagueSeasonsContext.Provider>
  );
};

OrgLeagueSeasonsProvider.defaultProps = {
  children: undefined,
};

export { OrgLeagueSeasonsProvider, useOrgLeagueSeasons };
