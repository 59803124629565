import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { OrganizationUserRoles, User } from '../generated/graphql';
import { useToggle } from '../hooks/toggle.hook';
import {
  clearUserContextStateLocalStorage,
  getUserContextStateLocalStorage,
  setUserContextStateLocalStorage,
} from '../local-storage/local-storage';
import {
  CLEAR_CURRENT_CONTEXT,
  SET_ALL_CONTEXTS,
  SET_ALL_CONTEXTS_AND_RESTORE_CURRENT_CONTEXT,
  SET_CURRENT_CONTEXT,
} from '../store/user-context/user-context.store.actions';
import { initialState, userContextReducer } from '../store/user-context/user-context.store.reducer';
import {
  ADMIN_CONTEXT,
  UserContext,
  UserRoleTypes,
} from '../store/user-context/user-context.store.types';
import { AuthContextType, useAuth } from './auth.context';

export type UserRoleContextType = {
  user: User | null;
  isAdmin: () => boolean;
  isOrgAdmin: () => boolean;
  isOrgUser: () => boolean;
  isEndUser: () => boolean;
  isAdminForOrg: (orgId: string) => boolean;
  allContexts: UserContext[];
  currentContext: UserContext | null;
  setUserContext: (newContext: UserContext) => void;
  clearUserContext: () => void;
};

const UserRoleContext = React.createContext<UserRoleContextType | null>(null);
UserRoleContext.displayName = 'UserRoleContext';
const useUserRole = () => React.useContext(UserRoleContext);

const UserRoleProvider: React.FC<React.ReactNode> = ({ children }) => {
  const { me } = useAuth() as AuthContextType;
  const history = useHistory();
  const [state, dispatch] = useImmerReducer(
    userContextReducer,
    getUserContextStateLocalStorage() ?? initialState,
  );
  const [contextLoaded, { on, off }] = useToggle(false);

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    if (!contextLoaded && me) {
      on();
      const contexts: UserContext[] = [];
      if (me.isAdmin) {
        contexts.push(ADMIN_CONTEXT);
      }
      me.organizationMemberships?.forEach((m) => {
        const orgContext: UserContext = {
          type: UserRoleTypes.ORGANIZATION_USER,
          sport: m.sport,
          name: m.name,
          abbreviation: m.abbreviation,
          logo: m.logo ?? undefined,
          title: m.title ?? undefined,
          membership: { ...m },
        };
        if (m.roles?.some((r) => r === OrganizationUserRoles.OrganizationAdmin)) {
          orgContext.type = UserRoleTypes.ORGANIZATION_ADMIN;
        } else if (m.roles?.some((r) => r === OrganizationUserRoles.OrganizationAssociate)) {
          orgContext.type = UserRoleTypes.ORGANIZATION_ASSOCIATE;
        }
        contexts.push(orgContext);
      });

      const lastContext = getUserContextStateLocalStorage();
      if (lastContext && lastContext.currentContext) {
        const lastCurrentContext = lastContext.currentContext;
        const canUseLastContext = contexts.find((c) => c.name === lastCurrentContext.name);
        if (canUseLastContext) {
          dispatch({
            type: SET_ALL_CONTEXTS_AND_RESTORE_CURRENT_CONTEXT,
            payload: {
              allContexts: contexts,
              currentContext: lastCurrentContext,
            },
          });
          return;
        }
      }
      dispatch({ type: SET_ALL_CONTEXTS, payload: contexts });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    if (contextLoaded && !me) {
      off();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextLoaded]);

  // eslint-disable-next-line @kyleshevlin/prefer-custom-hooks
  useEffect(() => {
    if (state) {
      setUserContextStateLocalStorage(state);
    } else {
      clearUserContextStateLocalStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const setUserContext = (newContext: UserContext) => {
    if (me) {
      dispatch({ type: SET_CURRENT_CONTEXT, payload: newContext });
      history.push('/');
    }
  };

  const clearUserContext = () => {
    if (me) {
      dispatch({ type: CLEAR_CURRENT_CONTEXT });
      history.push('/');
    }
  };

  // #region user type checks

  const isAdmin = (): boolean => {
    if (!me) {
      return false;
    }
    return me.isAdmin;
  };

  const isOrgAdmin = (): boolean => {
    if (!me) {
      return false;
    }
    return me.organizationMemberships.some((m) =>
      m.roles.some((r) => r === OrganizationUserRoles.OrganizationAdmin),
    );
  };

  const isOrgUser = (): boolean => {
    if (!me) {
      return false;
    }
    return me.organizationMemberships.some((m) =>
      m.roles.some((r) => r === OrganizationUserRoles.OrganizationUser),
    );
  };

  const isEndUser = (): boolean => {
    if (!me) {
      return false;
    }
    // TODO: implement once have end user memberships
    return false;
  };

  const isAdminForOrg = (orgId: string): boolean =>
    !!me &&
    (me.isAdmin ||
      me.organizationMemberships?.some(
        (m) =>
          m.orgId === orgId && m.roles?.some((r) => r === OrganizationUserRoles.OrganizationAdmin),
      ));

  // #endregion

  return (
    <UserRoleContext.Provider
      value={{
        isAdmin,
        isOrgAdmin,
        isOrgUser,
        isEndUser,
        isAdminForOrg,
        user: me,
        allContexts: state.allContexts,
        currentContext: state.currentContext,
        setUserContext,
        clearUserContext,
      }}
    >
      {children}
    </UserRoleContext.Provider>
  );
};

export { UserRoleProvider, useUserRole };
