import { useMemo, useState } from 'react';

export const useToggle = (initialState: boolean) => {
  const [toggleState, setToggleState] = useState(initialState);
  const handlers = useMemo(
    () => ({
      on: () => {
        setToggleState(true);
      },
      off: () => {
        setToggleState(false);
      },
      toggle: () => {
        setToggleState(!toggleState);
      },
      set: (state: boolean) => {
        setToggleState(state);
      },
    }),
    [toggleState],
  );

  return [toggleState, handlers] as const;
};
