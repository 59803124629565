/* eslint-disable no-param-reassign */
import { Draft } from 'immer';
import {
  HIDE_SNACKBAR,
  SHOW_ERROR_SNACKBAR,
  SHOW_INFO_SNACKBAR,
  SHOW_SUCCESS_SNACKBAR,
  SHOW_WARNING_SNACKBAR,
} from './snackbar.store.actions';
import { SnackbarAction, SnackBarSeverity, SnackbarState } from './snackbar.store.types';

export const initialState: SnackbarState = {
  show: false,
  message: '',
  severity: SnackBarSeverity.INFO,
};

export const snackbarReducer = (draft: Draft<SnackbarState>, action: SnackbarAction) => {
  switch (action.type) {
    case SHOW_INFO_SNACKBAR:
      draft.show = true;
      draft.message = action.payload;
      draft.severity = SnackBarSeverity.INFO;
      break;
    case SHOW_SUCCESS_SNACKBAR:
      draft.show = true;
      draft.message = action.payload;
      draft.severity = SnackBarSeverity.SUCCESS;
      break;
    case SHOW_WARNING_SNACKBAR:
      draft.show = true;
      draft.message = action.payload;
      draft.severity = SnackBarSeverity.WARNING;
      break;
    case SHOW_ERROR_SNACKBAR:
      draft.show = true;
      draft.message = action.payload;
      draft.severity = SnackBarSeverity.ERROR;
      break;
    case HIDE_SNACKBAR:
      draft.show = false;
      draft.message = '';
      draft.severity = SnackBarSeverity.INFO;
      break;
    default:
      break;
  }
};
