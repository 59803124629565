import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useImmerReducer } from 'use-immer';
import {
  HIDE_SNACKBAR,
  SHOW_ERROR_SNACKBAR,
  SHOW_INFO_SNACKBAR,
  SHOW_SUCCESS_SNACKBAR,
  SHOW_WARNING_SNACKBAR,
} from '../store/snackbar/snackbar.store.actions';
import { initialState, snackbarReducer } from '../store/snackbar/snackbar.store.reducer';

export type SnackbarContextType = {
  info: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
};

const SnackbarContext = React.createContext<SnackbarContextType | null>(null);
SnackbarContext.displayName = 'SnackbarContext';
const useSnackbar = () => React.useContext(SnackbarContext);

const SnackbarProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [state, dispatch] = useImmerReducer(snackbarReducer, initialState);

  const info = (message: string) => {
    dispatch({ type: SHOW_INFO_SNACKBAR, payload: message });
  };

  const success = (message: string) => {
    dispatch({ type: SHOW_SUCCESS_SNACKBAR, payload: message });
  };

  const warning = (message: string) => {
    dispatch({ type: SHOW_WARNING_SNACKBAR, payload: message });
  };

  const error = (message: string) => {
    dispatch({ type: SHOW_ERROR_SNACKBAR, payload: message });
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: HIDE_SNACKBAR });
  };

  return (
    <SnackbarContext.Provider value={{ info, warning, error, success }}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={state.show}
        autoHideDuration={6000}
        onClose={handleClose}
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert onClose={handleClose} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, useSnackbar };
