// Waiting Actions
export const SET_WAITING = 'set_waiting';
export const SET_WAITING_ON = 'set_waiting_on';
export const SET_WAITING_OFF = 'set_waiting_off';
export const SET_WAITING_TEXT = 'set_waiting_text';
export const SET_WAITING_TEXT_LOADING = 'set_waiting_text_loading';
export const SET_WAITING_TEXT_SAVING = 'set_waiting_text_saving';
export const SET_WAITING_TEXT_SUBMITTING = 'set_waiting_text_submitting';
export const SET_WAITING_TEXT_CREATING = 'set_waiting_text_creating';
export const SET_WAITING_TEXT_UPLOADING = 'set_waiting_text_uploading';
export const SET_WAITING_AND_WAITING_TEXT = 'set_waiting_and_waiting_text';
