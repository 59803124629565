import { OrganizationMembership, Sports } from '../../generated/graphql';

export enum UserRoleTypes {
  ADMIN = 'ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  ORGANIZATION_USER = 'ORGANIZATION_USER',
  ORGANIZATION_ASSOCIATE = 'ORGANIZATION_ASSOCIATE',
  LEAGUE_MEMBERSHIP = 'LEAGUE_MEMBERSHIP',
}

export const ADMIN_CONTEXT: UserContext = {
  type: UserRoleTypes.ADMIN,
  name: 'FairWhistle',
  abbreviation: 'FW',
  title: 'Admin',
};

export interface LeagueMembershipContext {
  id: string;
  name: string;
  abbreviation?: string | null;
  logo?: string | null;
  currentSeasonId?: string;
}

export interface UserContext {
  type: UserRoleTypes;
  name: string;
  sport?: Sports;
  abbreviation?: string;
  logo?: string;
  title?: string;
  membership?: OrganizationMembership;
  leagueMembership?: LeagueMembershipContext;
}

export interface UserContextState {
  allContexts: UserContext[];
  currentContext: UserContext | null;
}

export interface UserContextAction {
  type: string;
  payload?: any;
}
