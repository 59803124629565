/* eslint-disable no-param-reassign */
import { Draft } from 'immer';
import { SET_CURRENT_ORG_LEAGUE_SEASON } from './org-league-seasons.store.actions';
import { OrgLeagueSeasonsAction, OrgLeagueSeasonsState } from './org-league-seasons.store.types';

export const initialState = (): OrgLeagueSeasonsState => ({
  currentLeague: null,
  currentSeason: null,
  orgFeatures: [],
});

export const orgLeagueSeasonsReducer = (
  draft: Draft<OrgLeagueSeasonsState>,
  action: OrgLeagueSeasonsAction,
) => {
  switch (action.type) {
    case SET_CURRENT_ORG_LEAGUE_SEASON:
      draft.orgFeatures = action.payload.newFeatures ?? [];
      draft.currentLeague = action.payload.newLeague ?? null;
      draft.currentSeason = action.payload.newSeason ?? null;
      break;
    default:
      break;
  }
};
