export interface WaitingState {
  waiting: boolean;
  text: string | WaitingText;
}

export interface WaitingAction {
  type: string;
  payload?: any;
}

export enum WaitingText {
  ADDING = 'Adding...',
  LOADING = 'Loading...',
  SAVING = 'Saving...',
  SUBMITTING = 'Submitting...',
  CREATING = 'Creating...',
  UPLOADING = 'Uploading...',
  CONVERTING = 'Converting...',
  DELETING = 'Deleting...',
  DOWNLOADING = 'Downloading...',
  DEACTIVATING = 'Deactivating...',
  ACTIVATING = 'Activating...',
  UPDATING = 'Updating...',
  REMOVING = 'Removing...',
  RUNNING = 'Running...',
  SIGNING = 'Signing...',
  STARTING = 'Starting...',
}
